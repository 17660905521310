import { theme } from './theme'
import { mq } from './media'
import { css } from '@emotion/react'

export const px = (p: string) => ({
  paddingLeft: p,
  paddingRight: p,
})

export const py = (p: string) => ({
  paddingTop: p,
  paddingBottom: p,
})

export const pxGlobal = css(px(theme.space[2]), {
  maxWidth: theme.breakpoints.l,
  [mq.s]: {
    ...px(theme.space[3]),
  },
})

export const pyApp = css(py(theme.space[2]), {
  [mq.s]: {
    ...py(theme.space[3]),
  },
})
