import { css } from '@emotion/react'
import { theme } from './theme'
import { mq } from './media'
import { TColorMode } from '../types'

export const PORTOLIO_ITEM_TEXT_MAX_WIDTH = '50em'

export const titleStyle = css({
  fontSize: '1.25rem',
  fontWeight: 500,
})

export const subTitleStyle = (textColor: TColorMode) =>
  css({
    fontSize: '0.9rem',
    color: textColor === 'light' ? theme.colors.text.light.secondary : theme.colors.text.dark.secondary,
    letterSpacing: '0.2em',
    textTransform: 'uppercase',
  })

export const loginTitleLetterSpace = css({
  letterSpacing: '0.1em',
})

export const loginMainTitleStyle = css(
  {
    fontSize: '2rem',
    fontWeight: 500,
  },
  loginTitleLetterSpace
)

export const loginSubTitleStyle = css(loginMainTitleStyle, {
  fontSize: '1.125rem',
  opacity: 0.8,
})

export const totalsNumber = titleStyle

const headingRemSizes = {
  1: { xs: 2, l: 2.25, xl: 3 },
  2: { xs: 2, l: 3, xl: 4 },
  3: { xs: 2, l: 2.5, xl: 3 },
  4: { xs: 1.5, l: 1.75, xl: 2 },
}

export const Heading = (heading: 1 | 2 | 3 | 4 | 5 | 6, textColor: TColorMode, hasSubtitle?: boolean) => {
  return css({
    label: `Heading-general-style`,
    fontSize: headingRemSizes[heading].xs + 'rem',
    [mq.l]: {
      fontSize: headingRemSizes[heading].l + 'rem',
    },
    [mq.xl]: {
      fontSize: headingRemSizes[heading].xl + 'rem',
    },
    margin: 0,
    marginBottom: hasSubtitle ? '' : '0.5em',
    lineHeight: '1.5em',
    fontWeight: 500,
    color: textColor === 'light' ? '#FFFFFF' : theme.colors.text.dark.primary,
  })
}

export const bodyTextBase = css({
  label: 'body-text-base',
  p: {
    fontSize: '1rem',
    [mq.l]: {
      fontSize: '1.125rem',
    },
    [mq.xl]: {
      fontSize: '1.75rem',
    },
  },
})
