import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { theme } from '../../styles/theme'

type AnimationStates = 'initial' | 'show' | 'restart'

const animationSpeed = 0.125
const spiralCount = 9
const resetSpeed = animationSpeed * spiralCount + animationSpeed

export function GoldenSpiral({ initialized, visible }: { initialized: boolean; visible: boolean }) {
  const [spiralAnimationState, setSpiralAnimationState] = useState<AnimationStates>('initial')
  const [innerVisible, setInnerVisible] = useState(false)
  const [animationInit, setAnimationInit] = useState(false)

  function restartSpiral() {
    setSpiralAnimationState('restart')
    setTimeout(() => {
      setSpiralAnimationState('show')
    }, resetSpeed * 1000)
  }

  useEffect(() => {
    if (animationInit) {
      if (innerVisible && !visible) {
        setInnerVisible(false)
      }
      if (visible && !innerVisible) {
        setInnerVisible(true)
        restartSpiral()
      }
      if (!visible && !innerVisible) {
        setInnerVisible(false)
      }
    }
    if (initialized && !animationInit) {
      setSpiralAnimationState('show')
      setTimeout(() => {
        setAnimationInit(true)
      }, resetSpeed * 1000)
    }
  }, [initialized, visible, spiralAnimationState])

  // console.log(visible)

  function clickHandler() {
    switch (spiralAnimationState) {
      case 'initial':
        setSpiralAnimationState('show')
        break
      case 'show':
        restartSpiral()
        break
      default:
        break
    }
  }

  const spiralBoxVariants = {
    initial: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        when: 'beforeChildren',
      },
    },
    restart: {
      opacity: 1,
    },
  }

  const spiralPathVariantsBottom = {
    initial: {
      opacity: 0,
      pathLength: 1,
      pathOffset: 0,
      transition: {
        duration: animationSpeed,
      },
    },
    show: {
      opacity: [null, 1],
      pathLength: 1,
      pathOffset: 0,
      transition: {
        duration: 2,
      },
    },
    restart: {
      opacity: [1, 0],
      pathLength: 1,
      pathOffset: 0,
      transition: {
        duration: 2,
      },
    },
  }

  const topSpiralGroupVariants = {
    initial: {
      opacity: 0,
      transition: {
        duration: animationSpeed,
      },
    },
    show: {
      opacity: [null, 1],
      transition: {
        duration: 1,
      },
    },
    restart: {
      opacity: [1, 0],
      transition: {
        duration: resetSpeed,
      },
    },
  }

  const spiralPathVariantsTop = {
    initial: (i: number) => ({
      opacity: 0,
      pathLength: 0,
      pathOffset: 0,
      transition: {
        delay: animationSpeed * i,
        duration: animationSpeed,
      },
    }),
    show: (i: number) => ({
      opacity: [1, 1],
      pathLength: 1,
      pathOffset: 0,
      transition: {
        delay: animationSpeed * i,
        duration: animationSpeed,
      },
    }),
    restart: {
      opacity: [1, 0, 0],
      pathLength: [1, 1, 0],
      pathOffset: 0,
      transition: {
        duration: resetSpeed,
      },
    },
  }

  return (
    <>
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width="962"
        height="596"
        fill="none"
        viewBox="0 0 962 596"
        animate={spiralAnimationState}
        variants={spiralBoxVariants}
        onClick={clickHandler}
      >
        <path
          fill="#424242"
          d="M1 1.367v-.5H.5v.5H1zm593.455 0h.5v-.5h-.5v.5zm0 593.455v.5h.5v-.5h-.5zM1 594.822H.5v.5H1v-.5zM1 1.867h593.455v-1H1v1zm592.955-.5v593.455h1V1.367h-1zm.5 592.955H1v1h593.455v-1zm-592.955.5V1.367h-1v593.455h1z"
        />
        <path
          fill="#424242"
          d="M594.455 1.367v-.5h-.5v.5h.5zm366.545 0h.5v-.5h-.5v.5zm0 366.546v.5h.5v-.5h-.5zm-366.545 0h-.5v.5h.5v-.5zm0-366.046H961v-1H594.455v1zm366.045-.5v366.546h1V1.367h-1zm.5 366.046H594.455v1H961v-1zm-366.045.5V1.367h-1v366.546h1z"
        />
        <path
          fill="#424242"
          d="M734.091 367.913v-.5h-.5v.5h.5zm226.909 0h.5v-.5h-.5v.5zm0 226.909v.5h.5v-.5h-.5zm-226.909 0h-.5v.5h.5v-.5zm0-226.409H961v-1H734.091v1zm226.409-.5v226.909h1V367.913h-1zm.5 226.409H734.091v1H961v-1zm-226.409.5V367.913h-1v226.909h1z"
        />
        <path
          fill="#424242"
          d="M594.455 455.186v-.5h-.5v.5h.5zm139.636 0h.5v-.5h-.5v.5zm0 139.636v.5h.5v-.5h-.5zm-139.636 0h-.5v.5h.5v-.5zm0-139.136h139.636v-1H594.455v1zm139.136-.5v139.636h1V455.186h-1zm.5 139.136H594.455v1h139.636v-1zm-139.136.5V455.186h-1v139.636h1z"
        />
        <path
          fill="#424242"
          d="M594.455 367.913v-.5h-.5v.5h.5zm87.272 0h.5v-.5h-.5v.5zm0 87.273v.5h.5v-.5h-.5zm-87.272 0h-.5v.5h.5v-.5zm0-86.773h87.272v-1h-87.272v1zm86.772-.5v87.273h1v-87.273h-1zm.5 86.773h-87.272v1h87.272v-1zm-86.772.5v-87.273h-1v87.273h1z"
        />
        <path
          fill="#424242"
          d="M681.728 367.913v-.5h-.5v.5h.5zm52.363 0h.5v-.5h-.5v.5zm0 52.363v.5h.5v-.5h-.5zm-52.363 0h-.5v.5h.5v-.5zm0-51.863h52.363v-1h-52.363v1zm51.863-.5v52.363h1v-52.363h-1zm.5 51.863h-52.363v1h52.363v-1zm-51.863.5v-52.363h-1v52.363h1z"
        />
        <path
          fill="#424242"
          d="M699.182 420.276v-.5h-.5v.5h.5zm34.909 0h.5v-.5h-.5v.5zm0 34.91v.5h.5v-.5h-.5zm-34.909 0h-.5v.5h.5v-.5zm0-34.41h34.909v-1h-34.909v1zm34.409-.5v34.91h1v-34.91h-1zm.5 34.41h-34.909v1h34.909v-1zm-34.409.5v-34.91h-1v34.91h1z"
        />
        <path
          fill="#424242"
          d="M681.728 437.731v-.5h-.5v.5h.5zm17.454 0h.5v-.5h-.5v.5zm0 17.455v.5h.5v-.5h-.5zm-17.454 0h-.5v.5h.5v-.5zm0-16.955h17.454v-1h-17.454v1zm16.954-.5v17.455h1v-17.455h-1zm.5 16.955h-17.454v1h17.454v-1zm-16.954.5v-17.455h-1v17.455h1z"
        />
        <path
          fill="#424242"
          d="M681.728 420.276v-.5h-.5v.5h.5zm17.454 0h.5v-.5h-.5v.5zm0 17.455v.5h.5v-.5h-.5zm-17.454 0h-.5v.5h.5v-.5zm0-16.955h17.454v-1h-17.454v1zm16.954-.5v17.455h1v-17.455h-1zm.5 16.955h-17.454v1h17.454v-1zm-16.954.5v-17.455h-1v17.455h1z"
        />
        <g>
          <motion.path
            variants={spiralPathVariantsBottom}
            stroke={theme.colors.spiral.primary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M1 594.822C1 267.066 266.699 1.367 594.455 1.367"
          />
          <motion.path
            variants={spiralPathVariantsBottom}
            stroke={theme.colors.spiral.secondary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M594.455 1.367C796.892 1.367 961 165.475 961 367.913"
          />
          <motion.path
            variants={spiralPathVariantsBottom}
            stroke={theme.colors.spiral.primary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M961 367.913c0 125.318-101.591 226.909-226.909 226.909"
          />
          <motion.path
            variants={spiralPathVariantsBottom}
            stroke={theme.colors.spiral.secondary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M734.091 594.822c-77.119 0-139.636-62.517-139.636-139.636"
          />
          <motion.path
            variants={spiralPathVariantsBottom}
            stroke={theme.colors.spiral.primary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M594.455 455.186c0-48.2 39.073-87.273 87.272-87.273"
          />
          <motion.path
            variants={spiralPathVariantsBottom}
            stroke={theme.colors.spiral.secondary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M681.727 367.913c28.92 0 52.364 23.444 52.364 52.363"
          />
          <motion.path
            variants={spiralPathVariantsBottom}
            stroke={theme.colors.spiral.primary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M734.091 420.276c0 19.28-15.63 34.91-34.909 34.91"
          />
          <motion.path
            variants={spiralPathVariantsBottom}
            stroke={theme.colors.spiral.secondary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M699.182 455.186c-9.64 0-17.454-7.815-17.454-17.455"
          />
          <motion.path
            variants={spiralPathVariantsBottom}
            stroke={theme.colors.spiral.primary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M681.728 437.731c0-9.64 7.814-17.455 17.454-17.455"
          />
        </g>
        <motion.g variants={topSpiralGroupVariants}>
          <motion.path
            custom={0}
            variants={spiralPathVariantsTop}
            stroke={theme.colors.spiral.primary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M1 594.822C1 267.066 266.699 1.367 594.455 1.367"
          />
          <motion.path
            custom={1}
            variants={spiralPathVariantsTop}
            stroke={theme.colors.spiral.secondary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M594.455 1.367C796.892 1.367 961 165.475 961 367.913"
          />
          <motion.path
            custom={2}
            variants={spiralPathVariantsTop}
            stroke={theme.colors.spiral.primary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M961 367.913c0 125.318-101.591 226.909-226.909 226.909"
          />
          <motion.path
            custom={3}
            variants={spiralPathVariantsTop}
            stroke={theme.colors.spiral.secondary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M734.091 594.822c-77.119 0-139.636-62.517-139.636-139.636"
          />
          <motion.path
            custom={4}
            variants={spiralPathVariantsTop}
            stroke={theme.colors.spiral.primary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M594.455 455.186c0-48.2 39.073-87.273 87.272-87.273"
          />
          <motion.path
            custom={5}
            variants={spiralPathVariantsTop}
            stroke={theme.colors.spiral.secondary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M681.727 367.913c28.92 0 52.364 23.444 52.364 52.363"
          />
          <motion.path
            custom={6}
            variants={spiralPathVariantsTop}
            stroke={theme.colors.spiral.primary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M734.091 420.276c0 19.28-15.63 34.91-34.909 34.91"
          />
          <motion.path
            custom={7}
            variants={spiralPathVariantsTop}
            stroke={theme.colors.spiral.secondary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M699.182 455.186c-9.64 0-17.454-7.815-17.454-17.455"
          />
          <motion.path
            custom={8}
            variants={spiralPathVariantsTop}
            stroke={theme.colors.spiral.primary}
            strokeLinecap="round"
            strokeWidth="2"
            d="M681.728 437.731c0-9.64 7.814-17.455 17.454-17.455"
          />
        </motion.g>
      </motion.svg>
      {/* <p onClick={() => setSpiralAnimationState('initial')}>I</p>
      <p>{spiralAnimationState}</p> */}
    </>
  )
}
