import React from 'react'
import { css } from '@emotion/react'
import { subTitleStyle, Heading } from '../../styles/text'
import { spaceChildrenY } from '../../styles/space'
import { px, py } from '../../styles/padding'
import { mq } from '../../styles/media'
import { theme } from '../../styles/theme'
import { GalleryButton } from '../Button/Button'
import { SRLWrapper, useLightbox } from 'simple-react-lightbox'
import bonvinLogo from '../../images/earlier/bonvin-logo.jpg'
import cloudatorBrochure from '../../images/earlier/cloudator-brochure.jpg'
import cloudator3dInside from '../../images/earlier/cloudator-brochure-3d-inside.jpg'
import cloudator3dIpad from '../../images/earlier/cloudator-brochure-3d-ipad.jpg'
import cloudator3dOutside from '../../images/earlier/cloudator-brochure-3d-outside.jpg'
// import cloudatorHome from '../../images/earlier/cloudator-home-1920.jpg'
import cloudator3dStand from '../../images/earlier/cloudator-hr-tech-stand-collage.jpg'
import cloudatorRespHome from '../../images/earlier/cloudator-responsive-landing-home.jpg'
import cloudatorRespAccordion from '../../images/earlier/cloudator-responsive-accordion-component.jpg'
import cloudatorRespHub from '../../images/earlier/cloudator-responsive-article-hub.jpg'
import cloudatorRespToc from '../../images/earlier/cloudator-responsive-document-toc.jpg'
// import cloudatorRespHome2 from '../../images/earlier/cloudator-responsive-landing-home-2.jpg'
// import cloudatorRespPayroll from '../../images/earlier/cloudator-responsive-landing-payroll.jpg'
import cloudatorRespMenu from '../../images/earlier/cloudator-responsive-menu-features.jpg'
import fountain from '../../images/earlier/fountain.jpg'
// import mayabari1 from '../../images/earlier/mayabari-1.jpg'
// import mayabari2 from '../../images/earlier/mayabari-2.jpg'
// import mayabari3 from '../../images/earlier/mayabari-3.jpg'
// import mayabari4 from '../../images/earlier/mayabari-4.jpg'
// import mayabari5 from '../../images/earlier/mayabari-5.jpg'
// import mayabari6 from '../../images/earlier/mayabari-6.jpg'
import mayabari7 from '../../images/earlier/mayabari-7.jpg'
// import mayabari8 from '../../images/earlier/mayabari-6.jpg'
import photoArico from '../../images/earlier/photo-arico.jpg'
import photoClimbingCollage from '../../images/earlier/photo-climbing-collage.jpg'
import photoDh from '../../images/earlier/photo-dh.jpg'
import photoTenerifeExp from '../../images/earlier/teneriffa-explodiert.jpg'
// import photoEric from '../../images/earlier/photo-eric.jpg'
import ponkes1 from '../../images/earlier/ponkes-1.jpg'
import ponkes2 from '../../images/earlier/ponkes-schools-out.jpg'
import ponkes3 from '../../images/earlier/ponkes-2.jpg'

const TEXT_MAX_WIDTH = '50em'

const imgWrap = css({
  flex: '0 1 100%',
  boxShadow: theme.colors.shadow.darkCardOnWhite,
})

const EarlierWorkGallery = [
  {
    src: cloudatorRespHome,
    height: 'auto',
  },
  {
    src: cloudatorRespMenu,
    height: 'auto',
  },
  {
    src: cloudatorRespHub,
    height: 'auto',
  },
  {
    src: cloudatorRespToc,
    height: 'auto',
  },
  {
    src: cloudatorRespAccordion,
    height: 'auto',
  },
  {
    src: cloudator3dInside,
    height: 'auto',
  },
  {
    src: cloudator3dOutside,
    height: 'auto',
  },
  {
    src: cloudator3dIpad,
    height: 'auto',
  },
  {
    src: cloudatorBrochure,
    height: 'auto',
  },
  {
    src: cloudator3dStand,
    height: 'auto',
  },
  {
    src: photoClimbingCollage,
    height: 'auto',
  },
  {
    src: photoArico,
    height: 'auto',
  },
  {
    src: photoTenerifeExp,
    height: 'auto',
  },
  {
    src: photoDh,
    height: 'auto',
  },
  {
    src: ponkes1,
    height: 'auto',
  },
  {
    src: ponkes3,
    height: 'auto',
  },
  {
    src: ponkes2,
    height: 'auto',
  },
  {
    src: mayabari7,
    height: 'auto',
  },
  {
    src: bonvinLogo,
    height: 'auto',
  },
  {
    src: fountain,
    height: 'auto',
  },
]

export function EarlierWork() {
  const { openLightbox } = useLightbox()

  return (
    <>
      <SRLWrapper elements={EarlierWorkGallery} />
      <section
        css={css({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: theme.colors.background.gray,
        })}
      >
        <div
          css={css(py('4rem'), {
            label: 'section-wrapper',
            display: 'flex',
            justifyContent: 'center',
            [mq.l]: {
              marginLeft: '6rem',
              paddingTop: '8rem',
              paddingBottom: '8rem',
              justifyContent: 'flex-end',
            },
            [mq.xl]: {
              marginLeft: '12rem',
            },
          })}
        >
          <div
            css={css(px('2rem'), {
              label: 'section-items',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              maxWidth: TEXT_MAX_WIDTH,
              [mq.l]: {
                maxWidth: 'none',
                paddingLeft: 0,
                marginBottom: '0',
                paddingRight: 0,
                flexDirection: 'row-reverse',
              },
            })}
          >
            <div
              css={css({
                label: 'cloudator-images-wrapper',
                flex: '0 1 100%',
                display: 'flex',
                marginBottom: '2rem',
                '> *': {
                  '&:not(:last-of-type)': {
                    marginRight: '1rem',
                  },
                  '&:nth-of-type(2)': {
                    [mq.l]: {
                      marginRight: 0,
                    },
                    [mq.xl]: {
                      marginRight: '1rem',
                    },
                  },
                },
                [mq.l]: {
                  marginBottom: 0,
                },
                img: {
                  display: 'block',
                  flex: 1,
                  width: '100%',
                  height: 'auto',
                },
              })}
            >
              <div css={imgWrap}>
                <img src={photoClimbingCollage} alt="" />
              </div>
            </div>
            <div
              css={css(spaceChildrenY(2), {
                label: 'section-text',
                flex: '0 1 100%',
                fontSize: '1rem',
                [mq.l]: {
                  fontSize: '1.25rem',
                  marginRight: '4rem',
                  maxWidth: '35em',
                },
                [mq.xl]: {
                  fontSize: '1.75rem',
                  marginRight: '12rem',
                },
                '* p': {
                  color: theme.colors.text.dark.body,
                },
              })}
            >
              <div>
                <h3 css={css(Heading(3, 'dark', true))}>Earlier work</h3>
                <p css={subTitleStyle('dark')}>Designer & Developer & Creative | 2011-2019</p>
              </div>
              <div css={css(spaceChildrenY(1), {})}>
                <p>
                  {`I started my career as a graphical designer in 2011 but quickly started gathering multi-disciplinary
                  skills in website design and development and other creative arts such as photography, photo manipulations, videography, editing, animation and 3d
                  modeling. Here's some of my earlier work from this period.`}
                </p>
              </div>
              <div>
                <GalleryButton onClick={() => openLightbox()} color="dark" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
