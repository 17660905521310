import React from 'react'
import { TColorMode } from '../../types'
import { css } from '@emotion/react'
import { theme } from '../../styles/theme'

export function GalleryButton({ onClick, color }: { onClick: () => void; color: TColorMode }) {
  return (
    <button
      css={css({
        position: 'relative',
        background: 'transparent',
        color: color === 'light' ? theme.colors.text.light.primary : theme.colors.text.dark.primary,
        fontWeight: 500,
        fontSize: '1rem',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
        border: `2px solid ${theme.colors.orange}`,
        borderRadius: '2rem',
        padding: '.75rem 1.25rem',
        cursor: 'pointer',
      })}
      onClick={onClick}
    >
      Open gallery
    </button>
  )
}
