import React, { useEffect } from 'react'
import { css } from '@emotion/react'
import { motion, useAnimation } from 'framer-motion'
import { theme } from '../../styles/theme'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { setHeroFinished } from '../../redux/siteSlice'

export function DividerToArrow() {
  const continueArrowControls = useAnimation()

  const dispatch = useAppDispatch()
  const heroSubtitleVisible = useAppSelector(state => state.site.heroSubtitleVisible)

  useEffect(() => {
    if (heroSubtitleVisible) {
      continueArrowControls.start('transform').then(() => {
        continueArrowControls.start('move')
        dispatch(setHeroFinished(true))
      })
    }
  })

  const variants = {
    initial: {
      clipPath: 'polygon(45% 0%, 55% 0%, 55% 100%, 45% 100%)',
      y: 0,
    },
    transform: {
      clipPath: 'polygon(0% 0%, 100% 0%, 50% 100%, 50% 100%)',
      y: 0,
    },
    move: {
      clipPath: 'polygon(0% 0%, 100% 0%, 50% 100%, 50% 100%)',
      y: [null, 5],
      transition: {
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
  }

  return (
    <motion.div
      animate={continueArrowControls}
      variants={variants}
      css={css({
        width: '1em',
        height: '1em',
        backgroundColor: theme.colors.text.body,
        clipPath: 'polygon(45% 0%, 55% 0%, 55% 100%, 45% 100%)',
      })}
    ></motion.div>
  )
}
