export const ossItems = {
  starter: [
    {
      name: 'gatsby-starter-pikku-i18n',
      link: 'https://github.com/vanska/gatsby-starter-pikku-i18n',
    },
  ],
  plugins: [
    {
      name: 'gatsby-plugin-generate-i18n-navigation',
      link: 'https://github.com/vanska/gatsby-plugin-generate-i18n-navigation',
    },
    {
      name: 'gatsby-plugin-transform-site-config-json',
      link: 'https://github.com/vanska/gatsby-plugin-transform-site-config-json',
    },
    {
      name: 'gatsby-plugin-transform-i18n-locales',
      link: 'https://github.com/vanska/gatsby-plugin-transform-i18n-locales',
    },
    {
      name: 'gatsby-plugin-i18n-pages-from-json',
      link: 'https://github.com/vanska/gatsby-plugin-i18n-pages-from-json',
    },
    {
      name: 'gatsby-plugin-create-i18n-mdx-pages',
      link: 'https://github.com/vanska/gatsby-plugin-create-i18n-mdx-pages',
    },
  ],
  pikku: [
    {
      name: 'pikku-i18n',
      link: 'https://github.com/vanska/pikku-i18n',
    },
  ],
}
