import React from 'react'
import { PortfolioHeader } from '../PortfolioHeader/PortfolioHeader'
import { PortfolioItems } from '../PortfolioItems/PortfolioItems'

export function Portfolio() {
  return (
    <>
      <PortfolioHeader />
      <PortfolioItems />
    </>
  )
}
