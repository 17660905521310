import React from 'react'
import { css } from '@emotion/react'
import { subTitleStyle, Heading, PORTOLIO_ITEM_TEXT_MAX_WIDTH } from '../../styles/text'
import { spaceChildrenY } from '../../styles/space'
import { px, py } from '../../styles/padding'
import cloudator1Thumb from '../../images/cloudator/cloudator-1-thumb.png'
import cloudator2Thumb from '../../images/cloudator/cloudator-2-thumb.png'
import cloudator3Thumb from '../../images/cloudator/cloudator-3-thumb.png'
import { mq } from '../../styles/media'
import { theme } from '../../styles/theme'
import { GalleryButton } from '../Button/Button'
import { SRLWrapper, useLightbox } from 'simple-react-lightbox'
import Cloudator1 from '../../images/cloudator/cloudator-full-1.png'
import Cloudator2 from '../../images/cloudator/cloudator-full-2.png'
import Cloudator3 from '../../images/cloudator/cloudator-full-3.png'
import Cloudator4 from '../../images/cloudator/cloudator-full-4.png'
import Cloudator5 from '../../images/cloudator/cloudator-full-5.png'
import { portfolioSection } from '../../styles/section'

const cloudatorImageWrapper = css({
  flex: '0 1 100%',
  boxShadow: theme.colors.shadow.darkCardOnWhite,
})

const CloudatorGalleryItems = [
  {
    src: Cloudator1,
    height: 'auto',
  },
  {
    src: Cloudator2,
    height: 'auto',
  },
  {
    src: Cloudator3,
    height: 'auto',
  },
  {
    src: Cloudator4,
    height: 'auto',
  },
  {
    src: Cloudator5,
    height: 'auto',
  },
]

export function Cloudator() {
  const { openLightbox } = useLightbox()

  return (
    <>
      <SRLWrapper elements={CloudatorGalleryItems} />
      <section css={portfolioSection('light')}>
        <div className="section-wrapper">
          <div className="section-items">
            <div className="section-images">
              <div css={cloudatorImageWrapper}>
                <img src={cloudator1Thumb} alt="" />
              </div>
              <div
                css={css(cloudatorImageWrapper, {
                  [mq.l]: {
                    marginRight: '0',
                  },
                  [mq.xl]: {
                    marginRight: '1rem',
                  },
                })}
              >
                <img src={cloudator2Thumb} alt="" />
              </div>
              <div
                css={css(cloudatorImageWrapper, {
                  [mq.l]: {
                    display: 'none',
                  },
                  [mq.xl]: {
                    display: 'initial',
                  },
                })}
              >
                <img src={cloudator3Thumb} alt="" />
              </div>
            </div>
            <div className="section-text">
              <div>
                <h3 css={css(Heading(3, 'dark', true))}>Cloudator</h3>
                <p css={subTitleStyle('dark')}>Website redesign | Design & Development | 2021</p>
              </div>
              <div className="story">
                <p>
                  Cloudator needed a website design refresh for 2021 to highlight their new payroll product, modernize
                  the technology stack and improve the site’s lead generation and technical performance overall.
                </p>
                <p>
                  The landing page designs incorporate interactive components mimicking the ones found in the live
                  payroll product making the story more tangible.
                </p>
                <p>
                  Performance was one of the most critical design fundamentals for the project which was achieved by
                  using as light weight assets as possible to squeeze out every possible drop of performance. The
                  design’s visual elements are mostly interactive inline React components using the same font family as
                  the rest of the site with only two weights to keep the pages’ bundle sizes extremely small and to
                  avoid additional network requests for creative assets.
                </p>
              </div>
              <div>
                <h4 css={css(Heading(4, 'dark'))}>Technologies used</h4>
                <p>GatsbyJS, Preact, Typescript, Emotion, Google Firebase. Designed in Figma.</p>
              </div>
              <div>
                <GalleryButton onClick={() => openLightbox()} color="dark" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
