import { css } from '@emotion/react'

export const spaceChildrenY = (rem: number) =>
  css({
    '> *': {
      '&:not(:last-of-type)': {
        marginBottom: rem + 'rem',
      },
    },
  })

export const spaceChildrenX = (rem: number) =>
  css({
    '> *': {
      '&:not(:last-of-type)': {
        marginRight: rem + 'rem',
      },
    },
  })
