import React, { useRef, useEffect } from 'react'
import { css } from '@emotion/react'
import { SignatureLogo } from '../SignatureLogo/SignatureLogo'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { setHeroSubtitleVisible } from '../../redux/siteSlice'
import { motion, useAnimation } from 'framer-motion'
import { DividerToArrow } from '../DividerToArrow/DividerToArrow'
import { scroller } from 'react-scroll'
import { theme } from '../../styles/theme'
import { mq } from '../../styles/media'
import heroBgBubbles from '../../images/svg/hero-background-bubbles.svg'

export function Hero() {
  const logoAnimationFinished = useAppSelector(state => state.site.logoAnimationFinished)
  const heroFinished = useAppSelector(state => state.site.heroFinished)

  const dispatch = useAppDispatch()

  const ref = useRef<HTMLDivElement | null>(null)
  // const entry = useIntersectionObserver(ref, {})
  // const isVisible = !!entry?.isIntersecting

  const heroSubTitleControls = useAnimation()

  useEffect(() => {
    if (logoAnimationFinished) {
      heroSubTitleControls.start('show').then(() => {
        dispatch(setHeroSubtitleVisible(true))
      })
    }
  }, [logoAnimationFinished])

  // Scroll

  // Events.scrollEvent.register('end', function (to, element) {
  //   console.log('loginSection')
  // })

  function scrollClickHandler() {
    if (heroFinished) {
      scroller.scrollTo('loginSection', {
        duration: 1000,
        delay: 100,
        smooth: 'easeInOutCubic',
        offset: -100,
      })
    }
  }

  return (
    <div
      css={css({
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      })}
      ref={ref}
    >
      <div
        css={css({
          alignSelf: 'center',
          width: '100%',
          padding: '2rem',
          margin: 'auto',
          maxWidth: theme.breakpoints.l,
        })}
      >
        <SignatureLogo />
        <motion.div
          animate={heroSubTitleControls}
          initial="hidden"
          variants={{
            hidden: {
              y: -32,
              opacity: 0,
            },
            show: {
              y: 0,
              opacity: 0.75,
              transition: {
                duration: 0.6,
              },
            },
          }}
          css={css({
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: '.75rem',
            zIndex: 100,
            [mq.xs]: {
              fontSize: '1rem',
            },
            [mq.m]: {
              fontSize: '1.5rem',
            },
            marginTop: '2em',
            letterSpacing: '0.5em',
            marginBottom: '2rem',
            textTransform: 'uppercase',
            cursor: heroFinished ? 'pointer' : 'initial',
          })}
          onClick={scrollClickHandler}
        >
          <div
            css={css({
              flex: 1,
              textAlign: 'right',
            })}
          >
            Designer
          </div>
          <div
            css={css({
              padding: '.25em 1em',
            })}
          >
            <DividerToArrow />
          </div>
          <div
            css={css({
              flex: 1,
              textAlign: 'left',
            })}
          >
            Developer
          </div>
        </motion.div>
      </div>
      <div
        css={css({
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `url(${heroBgBubbles})`,
          backgroundRepeat: 'repeat',
          backgroundSize: '150%',
          backgroundPosition: 'bottom',
          pointerEvents: 'none',
          zIndex: 10,
        })}
      ></div>
      {/* <HeroOverLay /> */}
    </div>
  )
}
