import React from 'react'
import { css } from '@emotion/react'
import { Heading } from '../../styles/text'
import { mq } from '../../styles/media'
import { TextHero } from './TextHero'
import { Cloudator } from './Cloudator'
import { EarlierWork } from './EarlierWork'
import { Oss } from './Oss'
import SimpleReactLightbox from 'simple-react-lightbox'

const TEXT_MAX_WIDTH = '50em'

export function PortfolioItems() {
  return (
    <>
      <SimpleReactLightbox>
        <TextHero />
      </SimpleReactLightbox>
      <SimpleReactLightbox>
        <Cloudator />
      </SimpleReactLightbox>
      <Oss />
      <SimpleReactLightbox>
        <EarlierWork />
      </SimpleReactLightbox>
    </>
  )
}
