import React, { useState, useEffect } from 'react'
import { css } from '@emotion/react'
import ReactPlayer from 'react-player'
import { motion } from 'framer-motion'
import SignatureAnimation from '../../video/signature-logo.mp4'
import { useAppDispatch } from '../../redux/hooks'
import { setLogoAnimation } from '../../redux/siteSlice'
// import { useIntersectionObserver } from '../../hooks/useIntersectionObserver'

type TLogoStates = 'loading' | 'ready' | 'play'
type TWraperStates = 'hide' | 'show'

export function SignatureLogo() {
  // const animationWrapperRef = useRef<HTMLDivElement | null>(null)
  // const entry = useIntersectionObserver(animationWrapperRef, {})
  // const isVisible = !!entry?.isIntersecting

  let signaturePlayerRef: ReactPlayer | null = null

  const dispatch = useAppDispatch()

  const [logoAnimate, setLogoAnimate] = useState<TLogoStates>('loading')
  const [logoWrapperAnimate, setlogoWrapperAnimate] = useState<TWraperStates>('hide')

  const ANIMATION_DURATION = 600

  const logoWrapperVariants = {
    hide: {
      opacity: 0,
      transition: {
        duration: ANIMATION_DURATION / 1000,
      },
    },
    show: {
      opacity: 1,
      transition: {
        duration: ANIMATION_DURATION / 1000,
      },
    },
  }

  function signatureOnClick() {
    if (logoAnimate === 'play') {
      setlogoWrapperAnimate('hide')
      setTimeout(() => {
        setLogoAnimate('ready')
      }, ANIMATION_DURATION + 100)
    }
  }

  function onAnimationLoaded() {
    if (logoAnimate === 'loading') {
      setLogoAnimate('ready')
    }
  }

  useEffect(() => {
    // Video animation needs a separate state for seeking video to fix visual bug
    if (logoAnimate === 'ready') {
      if (signaturePlayerRef) {
        signaturePlayerRef.seekTo(0, 'seconds')
      }
      setlogoWrapperAnimate('show')
      setTimeout(() => {
        setLogoAnimate('play')
      }, ANIMATION_DURATION + 100)
    }
  }, [logoAnimate, signaturePlayerRef])

  return (
    <motion.div
      css={css({
        width: '100%',
        cursor: 'pointer',
      })}
      animate={logoWrapperAnimate}
      variants={logoWrapperVariants}
      onClick={signatureOnClick}
    >
      <ReactPlayer
        muted
        ref={ref => (signaturePlayerRef = ref)}
        url={SignatureAnimation}
        playing={logoAnimate === 'play'}
        width="100%"
        height="100%"
        onReady={onAnimationLoaded}
        onEnded={() => dispatch(setLogoAnimation(true))}
      />
    </motion.div>
  )
}
