import React from 'react'
import { css } from '@emotion/react'
import { subTitleStyle, Heading } from '../../styles/text'
import { spaceChildrenY } from '../../styles/space'
import { mq } from '../../styles/media'
import { portfolioSection } from '../../styles/section'
import { theme } from '../../styles/theme'

import { ossItems } from './ossItems'

const ListStyle = css({
  listStyle: 'none',
  paddingLeft: 0,
  marginTop: '-1em',
  a: {
    textDecoration: 'none',
    color: theme.colors.background.blue,
    fontWeight: 500,
  },
  li: {
    display: 'inline-block',
    background: theme.colors.gradient.orange,
    padding: '0.75rem 1rem',
    margin: '0.5rem 0.5rem 0.5rem 0',
    borderRadius: '2rem',
    fontSize: '.9rem',
  },
})

const List = ({ list }: { list: { name: string; link: string }[] }) => (
  <ul css={ListStyle}>
    {list.map(item => (
      <li key={item.name}>
        <a href={item.link} target="_blank" rel="noreferrer">
          {item.name}
        </a>
      </li>
    ))}
  </ul>
)

export function Oss() {
  return (
    <section css={portfolioSection('dark')}>
      <div className="section-wrapper">
        <div className="section-items">
          <div
            css={css(spaceChildrenY(2), {
              label: 'section-text',
              flex: '0 1 100%',
              fontSize: '1rem',
              [mq.l]: {
                fontSize: '1.25rem',
                marginRight: '4rem',
                maxWidth: '35em',
              },
              [mq.xl]: {
                fontSize: '1.75rem',
                marginRight: '12rem',
              },
              '* p': {
                color: theme.colors.text.light.body,
                marginBottom: '1.5em',
              },
            })}
          >
            <div>
              <h3 css={css(Heading(3, 'light', true))}>OSS for GatsbyJS & i18n</h3>
              <p css={subTitleStyle('light')}>JAVASCRIPT DEVELOPMENT | 2020-2021</p>
            </div>
            <div css={css(spaceChildrenY(1), {})}>
              <p>
                Gatsby, a static rendering framework for React was missing plugins and libraries for production ready
                multi-language websites with support for complete server-side rendering.
              </p>
              <p>
                After taking a deep dive into the Gatsby life cycle and trying out different approaches with existing
                tools, I realized that I had to build my own tools especially catered for the hydration life cycle used
                by Gatsby.
              </p>
            </div>
            <div>
              <h4 css={css(Heading(4, 'light'))}>Boilerplate for multi-language GatsbyJS websites</h4>
              <p>
                gatsby-starter-pikku-i18n is a proof-of-concept boilerplate for Gatsby that provides a starting point
                for internationalized websites with support for config based page generation, server-side rendered paths
                and automated Google Firebase hosting rules based on the generated paths out-of-the-box.
              </p>
              <List list={ossItems.starter} />
            </div>
            <div>
              <h4 css={css(Heading(4, 'light'))}>Plugins behind the starter</h4>
              <p>
                These plugins cover most common multi-lingual website use cases from page template MDX content with
                config based page and navigation generation, server-side rendered paths and Google Firebase hosting
                rules for redirects and a GraphQL queries API for JSON locale strings.
              </p>
              <List list={ossItems.plugins} />
            </div>
            <div>
              <h4 css={css(Heading(4, 'light'))}>String interpolation library for JS and React</h4>
              <p>
                Existing i18n libraries for React rely on the Suspense API which is not available for server-side
                rendering used by Gatsby. The libraries are also often unncessarily heavy, increasing bundle sizes and
                slowing down TTI metrics thus adding to bad Lighthouse performance scores.
              </p>

              <p>
                pikku-i18n is a tiny TypeScript library for handling translations and string interpolations for any JS
                or React project. The library is built with React SSR and hydration in mind and even comes with helpful
                error messages making it easier to spot missing locales early on.
              </p>
              <p>
                The library takes inspiration and improves upon the APIs of some of the most well known i18n libraries
                in the JS & React ecosystem while keeping the footprint extremely small.
              </p>
              <p>This library is the final puzzle piece in the quest for the perfect multi-lingual Gatsby website.</p>
              <List list={ossItems.pikku} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
