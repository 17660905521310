import React, { useEffect, useRef, useState } from 'react'
import { css } from '@emotion/react'
import { GoldenSpiral } from '../GoldenSpiral/GoldenSpiral'
import { theme } from '../../styles/theme'
import { px, py } from '../../styles/padding'
import { mq } from '../../styles/media'
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver'
import { motion, useAnimation } from 'framer-motion'

export function PortfolioHeader() {
  const PortfolioAnimationDivRef = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(PortfolioAnimationDivRef, {})
  const isVisible = !!entry?.isIntersecting
  const [portfolioInit, setPortfolioInit] = useState<boolean>(false)

  const portfolioHeaderAnimControl = useAnimation()

  useEffect(() => {
    if (isVisible && !portfolioInit) {
      portfolioHeaderAnimControl.start('show').then(() => {
        setPortfolioInit(true)
      })
    }
  })

  return (
    <section>
      <div
        css={css({
          padding: '4rem 2rem',
          display: 'flex',
          justifyContent: 'center',
          [mq.l]: {
            padding: '8rem 2rem',
          },
        })}
      >
        <motion.div
          animate={portfolioHeaderAnimControl}
          initial="hidden"
          ref={PortfolioAnimationDivRef}
          css={css({
            label: 'Portfolio-header',
            maxWidth: theme.breakpoints.m,
            flex: 1,
            svg: {
              width: '100%',
              height: 'auto',
              outline: 0,
              border: 0,
            },
          })}
        >
          <motion.h1
            variants={{
              hidden: {
                opacity: 0,
                y: -32,
              },
              show: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 1,
                },
              },
            }}
            css={css({
              position: 'relative',
              textAlign: 'center',
              fontSize: '2rem',
              [mq.xs]: {
                fontSize: '4rem',
              },
              [mq.m]: {
                fontSize: '7rem',
              },
              letterSpacing: '0.3em',
              fontWeight: 500,
              flex: 0,
              width: '100%',
              margin: 0,
              color: 'white',
              zIndex: 10,
              marginBottom: '-0.75em',
            })}
          >
            Portfolio
          </motion.h1>
          <GoldenSpiral initialized={portfolioInit} visible={isVisible} />
        </motion.div>
      </div>
    </section>
  )
}
