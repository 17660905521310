import React, { useEffect } from 'react'
import { css } from '@emotion/react'
import { theme } from '../../styles/theme'
import { useAppDispatch } from '../../redux/hooks'
import { setVisitorName } from '../../redux/siteSlice'

export interface LayoutProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode
}

const Layout = ({ children, ...props }: LayoutProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (window) {
      const params = new URLSearchParams(location.search)
      const visitor = params.get('visitor')
      if (visitor) {
        window.history.pushState({}, '', '/')
        dispatch(setVisitorName(visitor))
      }
    }
  })

  return (
    <div>
      {/* <Header /> */}
      <main
        {...props}
        css={css({
          margin: 'auto',
          // maxWidth: theme.breakpoints.l,
          overflow: 'visible',
        })}
      >
        {children}
      </main>
    </div>
  )
}

export default Layout
