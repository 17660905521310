import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { Hero } from '../components/Hero/Hero'

import { Login } from '../components/Login/Login'
import { Portfolio } from '../components/Portfolio/Portfolio'
import { useAppSelector } from '../redux/hooks'
import { HeroDivider } from '../components/HeroDivider/HeroDivider'
import { LOGIN_SECTION } from '../constants/constants'

const IndexPage = () => {
  const heroFinished = useAppSelector(state => state.site.heroFinished)
  const loginContinue = useAppSelector(state => state.site.loginContinue)

  return (
    <Layout>
      <Seo title="Mikko Vänskä | Designer Developer" />
      <Hero />
      {heroFinished ? (
        <div name={LOGIN_SECTION}>
          <HeroDivider />
          <Login />
        </div>
      ) : null}
      {loginContinue ? <Portfolio /> : null}
    </Layout>
  )
}

export default IndexPage
