import { css } from '@emotion/react'
import { theme } from './theme'
// import { spaceChildrenY } from './space'
import { mq } from './media'
import { px } from './space'
import { PORTOLIO_ITEM_TEXT_MAX_WIDTH } from './text'
import { TColorMode } from '../types'

const spaceChildrenY = (rem: number) => ({
  '> *': {
    '&:not(:last-of-type)': {
      marginBottom: rem + 'rem',
    },
  },
})

export const py = (p: string) => ({
  paddingTop: p,
  paddingBottom: p,
})

export const portfolioSection = (color: TColorMode) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: color === 'light' ? theme.colors.background.gray : theme.colors.background.blue,
    '& .section-wrapper': {
      padding: '4rem 2rem',
      display: 'flex',
      justifyContent: 'center',
      [mq.l]: {
        marginLeft: '6rem',
        paddingTop: '8rem',
        paddingRight: 0,
        paddingBottom: '8rem',
        justifyContent: 'flex-start',
      },
      [mq.xl]: {
        marginLeft: '12rem',
      },
    },
    '& .section-items': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      maxWidth: PORTOLIO_ITEM_TEXT_MAX_WIDTH,
      [mq.l]: {
        maxWidth: 'none',
        paddingLeft: 0,
        marginBottom: '0',
        paddingRight: 0,
        flexDirection: 'row-reverse',
      },
    },
    '& .section-images': {
      flex: '0 1 100%',
      display: 'flex',
      marginBottom: '2rem',
      '> *': {
        '&:not(:last-of-type)': {
          marginRight: '1rem',
        },
        '&:nth-of-type(2)': {
          [mq.l]: {
            marginRight: 0,
          },
          [mq.xl]: {
            marginRight: '1rem',
          },
        },
      },
      [mq.l]: {
        marginBottom: 0,
      },
      img: {
        display: 'block',
        flex: 1,
        width: '100%',
        height: 'auto',
      },
    },
    '& .section-text': {
      flex: '0 1 100%',
      fontSize: '1rem',
      ...spaceChildrenY(2),
      [mq.l]: {
        fontSize: '1.25rem',
        marginRight: '4rem',
        // maxWidth: '35em',
      },
      [mq.xl]: {
        fontSize: '1.75rem',
        marginRight: '12rem',
      },
      p: {
        color: color === 'light' ? theme.colors.text.dark.body : theme.colors.text.light.body,
      },
      '& .story': {
        ...spaceChildrenY(1),
      },
    },
  })
