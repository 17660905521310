import React from 'react'
import { css } from '@emotion/react'
import { subTitleStyle, Heading, PORTOLIO_ITEM_TEXT_MAX_WIDTH } from '../../styles/text'
import { spaceChildrenY } from '../../styles/space'
import { portfolioSection } from '../../styles/section'
import { px, py } from '../../styles/padding'
import textHeroMainImg from '../../images/text-hero/text-hero-main.png'
import { mq } from '../../styles/media'
import { theme } from '../../styles/theme'
import { GalleryButton } from '../Button/Button'
import { SRLWrapper, useLightbox } from 'simple-react-lightbox'
import TextHeroSettings from '../../images/text-hero/text-hero-settings.png'
import TextHeroSync from '../../images/text-hero/text-hero-sync.png'
import TextHeroImport from '../../images/text-hero/text-hero-import.png'
import TextHeroBrowser from '../../images/text-hero/text-hero-browser.png'
import TextHeroSearch from '../../images/text-hero/text-hero-search.png'
import TextHeroEditor from '../../images/text-hero/text-hero-editor.png'

const imageWrapper = css({
  flex: '0 1 100%',
  boxShadow: theme.colors.shadow.darkCardOnWhite,
})

const TextHeroGalleryItems = [
  {
    src: TextHeroSettings,
    width: 1916,
    height: 'auto',
  },
  {
    src: TextHeroSync,
    width: 1916,
    height: 'auto',
  },
  {
    src: TextHeroImport,
    width: 1916,
    height: 'auto',
  },
  {
    src: TextHeroBrowser,
    width: 1916,
    height: 'auto',
  },
  {
    src: TextHeroSearch,
    width: 1916,
    height: 'auto',
  },
  {
    src: TextHeroEditor,
    width: 1916,
    height: 'auto',
  },
]

export function TextHero() {
  const { openLightbox } = useLightbox()

  return (
    <>
      <SRLWrapper elements={TextHeroGalleryItems} />
      <section css={portfolioSection('dark')}>
        <div
          className="section-wrapper"
          // css={css(py('4rem'), {
          //   label: 'section-wrapper',
          //   display: 'flex',
          //   marginRight: 'auto',
          //   justifyContent: 'center',
          //   [mq.l]: {
          //     marginLeft: '6rem',
          //     paddingTop: '8rem',
          //     paddingBottom: '8rem',
          //     justifyContent: 'flex-end',
          //   },
          //   [mq.xl]: {
          //     marginLeft: '12rem',
          //   },
          // })}
        >
          <div
            className="section-items"
            // css={css(px('2rem'), {
            //   label: 'section-items',
            //   display: 'flex',
            //   alignItems: 'center',
            //   flexDirection: 'column',
            //   maxWidth: PORTOLIO_ITEM_TEXT_MAX_WIDTH,
            //   [mq.l]: {
            //     maxWidth: 'none',
            //     paddingLeft: 0,
            //     marginBottom: '0',
            //     paddingRight: 0,
            //     flexDirection: 'row-reverse',
            //   },
            // })}
          >
            <div
              className="section-images"
              // css={css({
              //   label: 'cloudator-images-wrapper',
              //   flex: '0 1 100%',
              //   display: 'flex',
              //   marginBottom: '2rem',
              //   '> *': {
              //     '&:not(:last-of-type)': {
              //       marginRight: '1rem',
              //     },
              //     '&:nth-of-type(2)': {
              //       [mq.l]: {
              //         marginRight: 0,
              //       },
              //       [mq.xl]: {
              //         marginRight: '1rem',
              //       },
              //     },
              //   },
              //   [mq.l]: {
              //     marginBottom: 0,
              //   },
              //   img: {
              //     display: 'block',
              //     flex: 1,
              //     width: '100%',
              //     height: 'auto',
              //   },
              // })}
            >
              <div css={css(imageWrapper, {})}>
                <img src={textHeroMainImg} alt="Text Hero in Figma" />
              </div>
            </div>
            <div
              className="section-text"
              // css={css(spaceChildrenY(2), {
              //   label: 'section-text',
              //   flex: '0 1 100%',
              //   fontSize: '1rem',
              //   [mq.l]: {
              //     fontSize: '1.25rem',
              //     marginRight: '4rem',
              //     maxWidth: '35em',
              //   },
              //   [mq.xl]: {
              //     fontSize: '1.75rem',
              //     marginRight: '12rem',
              //   },
              //   '* p': {
              //     color: theme.colors.text.light.body,
              //   },
              // })}
            >
              <div>
                <h3 css={css(Heading(3, 'light', true))}>Text Hero</h3>
                <p css={subTitleStyle('light')}>Figma Plugin | UX/UI/DEV & Founder | 2020-2021</p>
              </div>
              <div className="story">
                <p>
                  Figma has no native way of managing text in documents which makes keeping UX copy in sync between
                  components extremely tedious and prone to errors and inconsistencies.
                </p>
                <p>
                  Text Hero solves this problem by extending Figma with a powerful and flexible text management workflow
                  and a two way data sync between design systems and development environments.
                </p>
              </div>
              <div>
                <h4 css={css(Heading(4, 'light'))}>Technologies used</h4>
                <p>Typescript, React, Redux, Emotion, Webpack, Babel, Jest. Designed in Figma.</p>
              </div>
              <div>
                <GalleryButton onClick={() => openLightbox()} color="light" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
