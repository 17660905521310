import React, { useRef, useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { motion } from 'framer-motion'
import { theme } from '../../styles/theme'
import { media } from '../../styles/media'

const ballStyle = (rem: number) =>
  css({
    width: rem + 'rem',
    height: rem + 'rem',
    borderRadius: rem + 'rem',
    position: 'absolute',
    background: theme.colors.gradient.orange,
  })
// import { HeroBottomDivider } from '../Svg/HeroBottomDivider'

// {
//   initial: {
//     y: 0
//   },
//   travel: {
//     y: '300%'
//   }
// }

const SVG_WIDTH = 17280

const BALL_ANIM_DURATION = 4

export function HeroDivider() {
  const dividerWrapperRef = useRef<HTMLDivElement>(null)

  const [dimensions, setDimensions] = useState<{
    wrapperWidth: number | null
    svgX: number | null
  }>({
    wrapperWidth: null,
    svgX: null,
  })

  function onClickHandler() {
    calculateDimensions()
  }

  function calculateDimensions() {
    const wrapperWidth = dividerWrapperRef.current?.clientWidth
    if (wrapperWidth) {
      const svgMaxXNative = SVG_WIDTH / 2
      const wrapperMinusSvgWidth = Math.abs(wrapperWidth - svgMaxXNative)
      // When wrapper is wider than svg add wrapper subtraction to svg max X length
      const svgMaxByWrapper = svgMaxXNative - wrapperMinusSvgWidth
      const svgX = wrapperWidth >= svgMaxXNative ? svgMaxByWrapper : svgMaxXNative + wrapperMinusSvgWidth
      setDimensions({
        wrapperWidth: wrapperWidth,
        svgX: -svgX,
      })
    }
  }

  useEffect(() => {
    if (dividerWrapperRef) {
      calculateDimensions()
    }
    // Recalculate svg dimensions for animation
    let doit: number
    window.onresize = () => {
      clearTimeout(doit)
      doit = setTimeout(() => calculateDimensions(), 10)
    }
  }, [dividerWrapperRef])

  return (
    <div
      ref={dividerWrapperRef}
      onClick={onClickHandler}
      css={css({
        position: 'relative',
        height: '144px',
        transform: 'translateY(1px)',
        overflow: 'hidden',
      })}
    >
      {dimensions.wrapperWidth ? (
        // <motion.svg
        //   xmlns="http://www.w3.org/2000/svg"
        //   width={SVG_WIDTH}
        //   height="144"
        //   fill="none"
        //   viewBox="0 0 4320 144"
        //   css={css({
        //     width: '4320px',
        //     height: '144px',
        //   })}
        //   animate={{
        //     x: [0, dimensions.svgX, 0],
        //   }}
        //   transition={{
        //     duration: 10,
        //     reverseType: 'reverse',
        //     repeat: Infinity,
        //   }}
        // >
        //   <path
        //     fill="#F7F7F7"
        //     d="M1030.99 66.792C623.399 64.442 0 0 0 0V144h4320V0s-610.56 63.754-1010.04 66.792C2858.48 70.225 2616.79-.238 2165.24 0c-447.51.236-686.78 69.37-1134.25 66.79z"
        //   ></path>
        // </motion.svg>
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          width="17280"
          height="144"
          fill="none"
          viewBox="0 0 17280 144"
          css={css({
            width: `${SVG_WIDTH}px`,
            height: '144px',
          })}
          animate={{
            x: [0, dimensions.svgX, 0],
          }}
          transition={{
            duration: 60,
            reverseType: 'reverse',
            repeat: Infinity,
            // // ease: [1, 0.3, 0.3, 1],
            // type: 'spring',
            // damping: 1000,
            // stiffness: 0,
            // mass: 1000,
          }}
        >
          <path
            fill="#F7F7F7"
            d="M17280 144V0s-1043.9 72-1728 72c-684.1 0-1043.9-71.904-1728-72-686.4-.096-1047.6 72.291-1734 72-679.5-.288-1036.5-71.807-1716-72-684.13-.194-1043.87 72-1728 72C7961.87 72 7602.13.096 6918 0c-686.45-.096-1047.55 72.194-1734 72C4502.18 71.808 4143.82.192 3462 0c-686.45-.193-1047.55 72.097-1734 72C1043.87 71.904 0 0 0 0v144h17280z"
          ></path>
        </motion.svg>
      ) : null}
      <div
        css={css({
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
        })}
      >
        <motion.span
          animate={{
            y: [0, 40, 0],
          }}
          transition={{
            duration: BALL_ANIM_DURATION,
            reverseType: 'reverse',
            repeat: Infinity,
          }}
          css={css(ballStyle(3), {
            left: '15%',
            top: 0,
          })}
        ></motion.span>
        <motion.span
          animate={{
            y: [20, 60, 20],
          }}
          transition={{
            duration: BALL_ANIM_DURATION,
            reverseType: 'reverse',
            repeat: Infinity,
            delay: 0.9,
          }}
          css={css(ballStyle(1.5), {
            left: '40%',
            top: 0,
          })}
        ></motion.span>
        <motion.span
          animate={{
            y: [10, 50, 10],
          }}
          transition={{
            duration: BALL_ANIM_DURATION,
            reverseType: 'reverse',
            repeat: Infinity,
            delay: 1.8,
          }}
          css={css(ballStyle(3), {
            right: '10%',
            top: 0,
          })}
        ></motion.span>
      </div>
    </div>
  )
}
